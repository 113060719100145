import { Link } from "react-router-dom";
import { BadgeStatus } from "./badge";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { calendarIcon } from "images";

export const RouteItem = ({ poster, title, status }) => {
  return (
    <div className="flex h-20 gap-5">
      <img className="w-36 h-full block" src={poster} alt="logo" />
      <div>
        <h3>
          <Link>{title}</Link>
          <div className="route-info flex gap-6 items-center">
            <BadgeStatus status={"approve"} />
            <div>
              <img src={calendarIcon} alt="" />
              <span>13-09-2024</span>
            </div>
          </div>
        </h3>
      </div>
    </div>
  );
};
