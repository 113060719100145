import { Outlet, useLocation } from "react-router-dom";
import { Sidebar, Header } from "./ui";

export const BackOfficePage = () => {
  const path = useLocation();

  if (path.pathname.includes("login")) return <Outlet />;

  return (
    <main className="backoffice montserrat">
      <Sidebar />
      <Header />
      <section className=" mt-[72px] ml-60 w-[calc(100vw-240px)] min-h-full bg-white">
        <div className="w-11/12 h-full mx-auto py-6">
          <Outlet />
        </div>
      </section>
    </main>
  );
};
