import { Icon } from "@shared/ui/icon";

export const CardItem = ({ title, total, count, icon }) => {
  return (
    <div className="rounded-2xl bg-[#F6F8FA] w-[32%] px-5 py-4 flex justify-between items-center">
      <div className="flex flex-col">
        <span className="text-[#A0AEC0] font-bold text-xs">{title}</span>
        <div className="flex mt-1 items-center gap-1">
          <span className="text-[#2D3748] font-bold text-xl">{total}</span>
          {count !== 0 && (
            <span className="text-[#48BB78]">+{count} новых</span>
          )}
        </div>
      </div>
      {icon && (
        <Icon
          fill={icon.fill}
          color={icon.bgFill}
          size={icon.size}
          icon={icon.image}
        />
      )}
    </div>
  );
};
