import { lazy } from "react";
import { Navigate } from "react-router-dom";

import Main from "./layouts/main";
import EventPage from "./layouts/eventPage";
import NotePage, { DynamicNoteBreadcrumb } from "./layouts/notePage";
import WayPage from "./layouts/wayPage";
import WaysMap from "./layouts/waysMap";

import Cookies from "js-cookie";

import { BookmarkOff, logoutIcon, logoutIconWhite } from "./images";
import { About, Media, Source, Maps, WayDescription } from "./pages/wayPage";
import { Contacts, Docs, Manual } from "./pages/support";
import {
  AuthIcon,
  Bookmark,
  OutdoorEventBreadcrumb,
  EventBreadcrumb,
} from "./components";

import { AddNote, Organization, Profile, Students } from "./pages/account";
import { Staff, CreateStaff } from "./pages/account/staff";
import { Suspense } from "react";
import { CreateSudent, StudentPage } from "./pages/account/student";
import { OrganizationEvents } from "./pages/account/organizationEvents";
import { Club } from "./layouts/club";
import { WayNotes } from "./pages/wayPage/notes";
import { useSelector } from "react-redux";
import EventsOrganizationForm from "./components/account/outdoor/form";
import EventOrganizationSuccessForm from "./components/account/outdoor/successForm";
import { AddRouteForm } from "@entities/route/ui";
import { BackOfficePage } from "@pages/backoffice";
import { Main as MainBackoffice } from "@features/backoffice/outlets";

const Favourites = lazy(() => import("./layouts/favourites"));
const Events = lazy(() => import("./layouts/events"));
const TravelNotes = lazy(() => import("./layouts/travelNotes"));
const Ways = lazy(() => import("./layouts/ways"));
const Support = lazy(() => import("./layouts/support"));
const Account = lazy(() => import("./layouts/account"));

export const bookmarkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
    />
  </svg>
);

export const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth.auth);
  // Check if the user is authenticated
  if (!auth) {
    return <Navigate to="/" />;
  }
  if (auth !== Cookies.get("access_token")) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" />;
  }

  // If authenticated, render the child routes
  return children;
};

export const routes = [
  {
    path: "",
    breadcrumb: "Главная",
    element: <Main />,
    display: true,
    title: "Главная",
    index: true,
  },
  {
    path: "/support",
    element: (
      <Suspense fallback={<span>loading</span>}>
        <Support />
      </Suspense>
    ),
    breadcrumb: "Методическая поддержка",
    display: true,
    title: "Методическая поддержка",
    children: [
      {
        path: "",
        index: true,
        element: <Manual />,
        display: true,
        title: "Городская система организационно-методической поддержки",
        // breadcrumb: "Городская система организационно-методической поддержки",
      },
      {
        path: "docs",
        element: <Docs />,
        display: true,
        title: "Мероприятия в среде",
        breadcrumb: "Мероприятия в среде",
      },
      // {
      //   path: "documents",
      //   element: <Documents />,
      //   display: true,
      //   title: "Документы",
      //   breadcrumb: "Документы",
      // },
      {
        path: "contacts",
        element: <Contacts />,
        display: true,
        title: "Консультация",
        breadcrumb: "Консультация",
      },
      {
        path: "https://юныйтурист.рф/navigation-page/07hdb2nipk8xwomq5du2icmlwmutuftl",
        element: (
          <a
            target="_blank"
            href="https://юныйтурист.рф/navigation-page/07hdb2nipk8xwomq5du2icmlwmutuftl"
          >
            Cайт "Юный турист"
          </a>
        ),
        display: true,
        title: 'Cайт "Юный турист"',
      },
    ],
  },
  {
    path: "/events",
    breadcrumb: "События",

    element: (
      <Suspense fallback={<span>loading</span>}>
        <Events />
      </Suspense>
    ),
    display: true,
    title: "События",
    children: [
      {
        path: ":id",
        display: true,
        breadcrumb: EventBreadcrumb,
        element: <EventPage />,
      },
    ],
  },
  {
    path: "/notes",
    breadcrumb: "Заметки",
    element: (
      <Suspense fallback={<span>loading</span>}>
        <TravelNotes />
      </Suspense>
    ),

    title: "Заметки",
    children: [
      {
        path: ":id",
        display: true,
        breadcrumb: DynamicNoteBreadcrumb,
        element: <NotePage />,
      },
    ],
  },
  {
    path: "/map",
    breadcrumb: "Карта",
    element: <WaysMap />,
    display: false,
    title: "Карта",
  },

  {
    path: "/ways",
    breadcrumb: "Маршруты",
    element: (
      <Suspense fallback={<span>loading</span>}>
        <Ways />
      </Suspense>
    ),
    display: true,
    title: "Библиотека маршрутов",
    children: [
      {
        path: ":id",
        display: true,
        element: <WayPage />,
        children: [
          {
            path: "",
            index: true,
            element: <About />,
            display: true,
            title: "О маршруте",
          },
          {
            path: "description",
            element: <WayDescription />,
            display: true,
            title: "Описание маршрута",
          },
          {
            path: "media",
            element: <Media />,
            display: true,
            title: "Медиаконтент",
          },
          {
            path: "map",
            element: <Maps />,
            display: true,
            title: "Интерактивная карта",
          },
          {
            path: "source",
            element: <Source />,
            display: true,
            title: "Источники",
          },
          {
            path: "notes",
            element: <WayNotes />,
            display: true,
            title: "Заметки",
          },
        ],
      },
    ],
  },

  // {
  //   path: "/club",
  //   element: <Club />,
  //   display: true,
  //   title: "Клуб",
  //   breadcrumb: "Московский педагогический клуб путешественников",
  // },

  {
    path: "/favorites",
    breadcrumb: "Избранное",
    element: (
      <Suspense fallback={<span>loading</span>}>
        <Favourites />
      </Suspense>
    ),
    display: true,
    title: <Bookmark icon={BookmarkOff} nav={true} />,
  },
  {
    path: "/account",
    breadcrumb: "Личный кабинет",
    element: (
      <Suspense fallback={<span>loading</span>}>
        {/* <ProtectedRoute> */}
        <Account />
        {/* </ProtectedRoute> */}
      </Suspense>
    ),

    title: <AuthIcon />,
    display: true,

    children: [
      {
        path: "",
        element: <Profile />,
        display: false,
        breadcrumb: "Личный кабинет",
        title: "Профиль",
      },
      {
        index: true,
        path: "",
        element: <Organization />,
        display: true,
        breadcrumb: "Организация",
        title: "Организация",
      },
      {
        path: "events",
        element: <OrganizationEvents />,
        display: true,
        breadcrumb: "Выездные мероприятия",
        title: "Выездные мероприятия",
        children: [
          {
            path: ":id",
            element: <EventsOrganizationForm />,
            display: true,
            breadcrumb: OutdoorEventBreadcrumb,
            title: "Выездные мероприятия",
            children: [
              {
                path: "success/",
                breadcrumb: "Страница подтверждения",
                element: <EventOrganizationSuccessForm />,
                display: true,
              },
              {
                path: "edit/",
                element: <EventsOrganizationForm />,
                display: true,
              },
              {
                path: "addNote",
                breadcrumb: "Добавить заметку",
                element: <AddNote />,
                display: true,
              },
            ],
          },
        ],
      },

      {
        path: "staff",
        element: <Staff />,
        display: true,
        breadcrumb: "Сотрудники",
        title: "Сотрудники",
        children: [
          {
            path: "create",
            element: <CreateStaff />,
            display: true,
            breadcrumb: "Добавить сотрудника",
          },
          {
            path: "edit",
            element: <CreateStaff />,
            display: true,
            breadcrumb: "Редактировать сотрудника",
          },
        ],
      },
      {
        path: "students",
        element: <Students />,
        display: false,
        breadcrumb: "Обучающиеся",
        title: "Обучающиеся",
        children: [
          {
            path: ":id",
            element: <StudentPage />,
            display: false,
            breadcrumb: null,
            title: "Обучающиеся",
          },
          {
            path: "create",
            element: <CreateSudent />,
            display: true,
            breadcrumb: "Добавить ученика",
          },
          {
            path: "edit",
            element: <CreateSudent />,
            display: true,
            breadcrumb: "Редактировать ученика",
          },
        ],
      },
      {
        path: "addRoute",
        element: <AddRouteForm />,
        display: true,
        breadcrumb: "Добавить маршрут",
        title: "Добавить маршрут",
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];

export const backOfficeRoutes = [
  {
    path: "/backoffice",
    breadcrumb: "Главная",
    element: <BackOfficePage />,
    display: true,
    title: "Главная",
    children: [
      {
        index: true,
        breadcrumb: "Создать маршрут",
        element: <MainBackoffice />,
        display: true,
        title: "Создать маршрут",
      },
      {
        path: "create",
        breadcrumb: "Создать маршрут",
        element: <div>создание маршрута</div>,
        display: true,
        title: "Создать маршрут",
      },
      {
        path: "work",
        breadcrumb: "Маршруты в работе",
        element: <div>Маршруты в работе</div>,
        display: true,
        title: "Маршруты в работе",
      },
      {
        path: "points",
        breadcrumb: "Точки на маршруте",
        element: <div>Точки на маршруте</div>,
        display: true,
        title: "Точки на маршруте",
      },
    ],
  },
];
