import { useState } from "react";
import { SelectField, CustomButton, Divider } from "@components";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import {
  useGetRoutesAgeCategoriesQuery,
  useGetRoutesAttractionsQuery,
  useGetRoutesNaturalEnvironmentsQuery,
  useGetRoutesRegionsQuery,
  useGetRoutesSeasonsQuery,
  useGetRoutesTagByIdQuery,
  useGetRoutesTagsQuery,
  useGetRoutesTargetAudiencesQuery,
  useGetRoutesTypesQuery,
} from "../../../../store/RTKApi/routes";
import { TextEditor } from "@shared/widgets";

export const AddRouteForm = () => {
  const [formData, setFormData] = useState({
    season: [],
    region: "",
    target_audience: [],
    age_category: [],
    durationDays: "",
    durationHours: "",
    type: "",
    length: "",
    attractions: [],
    natural_environments: "",
    author: "",
    directions: "",
    description: "",
    short_description: "",
    sources: [""],
  });
  const { data: seasons, isSuccess: seasonSuccess } =
    useGetRoutesSeasonsQuery();
  const { data: regions, isSuccess: regionSuccess } =
    useGetRoutesRegionsQuery();
  const { data: audience, isSuccess: audienceSuccess } =
    useGetRoutesTargetAudiencesQuery();
  const { data: age, isSuccess: ageSuccess } = useGetRoutesAgeCategoriesQuery();
  const { data: type, isSuccess: typeSuccess } = useGetRoutesTypesQuery();
  const { data: attractions, isSuccess: attractionsSuccess } =
    useGetRoutesAttractionsQuery();
  const { data: naturalEnvironment, isSuccess: naturalEnvironmentSuccess } =
    useGetRoutesNaturalEnvironmentsQuery();

  const { data: tags, isSuccess: tagsSuccess } = useGetRoutesTagsQuery();
  const attractionsTransformed = attractionsSuccess
    ? attractions
        .map((el) => {
          const firstLetter = el.title[0].toUpperCase();
          return {
            label: el.title,
            value: el.id,
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          };
        })
        .filter(
          (obj, index, self) =>
            index === self.findIndex((el) => el.label === obj.label)
        )
    : [];
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <section>
      {" "}
      <h2 className="text-2xl font-bold">Создание маршрута</h2>
      <form className="mt-10">
        {" "}
        <div className="grid grid-cols-3 gap-5">
          <FormControl fullWidth>
            <InputLabel id="season">Сезон</InputLabel>
            <Select
              multiple
              labelId="season"
              name="season"
              label="Сезон"
              value={formData.season}
              onChange={handleChange}
            >
              {seasonSuccess &&
                seasons.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="region">Регион</InputLabel>
            <Select
              labelId="region"
              name="region"
              label="Регион"
              value={formData.region}
              onChange={handleChange}
            >
              {regionSuccess &&
                regions.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <CustomButton
            styles={" w-full h-full min-w-[50px] !m-0 bg-text-primary-green"}
            label="+ Добавить новый регион"
            type="button"
          >
            Добавить новый регион
          </CustomButton>
        </div>
        <div className="grid grid-cols-3 gap-5 mt-7">
          <FormControl fullWidth>
            <InputLabel id="target_audience">Целевая аудитория</InputLabel>
            <Select
              multiple
              labelId="target_audience"
              name="target_audience"
              label="Целевая аудитория"
              value={formData.target_audience}
              onChange={handleChange}
            >
              {audienceSuccess &&
                audience.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="age_category">Возраст обучающихся</InputLabel>
            <Select
              multiple
              labelId="age_category"
              name="age_category"
              label="Возраст обучающихся"
              value={formData.age_category}
              onChange={handleChange}
            >
              {ageSuccess &&
                age.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex  gap-5 mt-7">
          <span className="font-bold">
            Продолжительность <br /> маршрута
          </span>
          <TextField
            label="Дни"
            name="durationDays"
            value={formData.durationDays}
            onChange={handleChange}
            className="max-w-[170px]"
            variant="outlined"
          />
          <TextField
            label="Часы"
            name="durationHours"
            value={formData.durationHours}
            onChange={handleChange}
            className="max-w-[170px]"
            variant="outlined"
          />
        </div>
        <div className="grid grid-cols-3 gap-5 mt-7">
          <FormControl fullWidth>
            <InputLabel id="type">Вид маршрута</InputLabel>
            <Select
              labelId="type"
              name="type"
              label="Вид маршрута"
              value={formData.type}
              onChange={handleChange}
            >
              {typeSuccess &&
                type.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Протяженность маршрута"
              name="length"
              value={formData.length}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-3 gap-5 mt-7">
          <FormControl fullWidth>
            <Autocomplete
              clearOnBlur
              multiple
              name="attractions"
              options={attractionsTransformed.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              label="Объекты на маршруте"
              value={formData.attractions}
              onChange={(e, value) =>
                handleChange({ target: { name: "attractions", value } })
              }
              filterSelectedOptions
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Объекты на маршруте"
                  placeholder="Объекты на маршруте"
                />
              )}
            />
            {/* {attractionsSuccess &&
                attractions.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    <Checkbox checked={formData.attractions.indexOf(s.id) > -1} />
                    <ListItemText primary={s.title} />
                  </MenuItem>
                ))} */}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="natural_environments">Природная среда</InputLabel>
            <Select
              name="natural_environments"
              label="Природная среда"
              value={formData.natural_environments}
              onChange={handleChange}
            >
              {naturalEnvironmentSuccess &&
                naturalEnvironment.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.difficulty}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {/* Добавьте остальные поля формы */}
        <Divider />
        <div className="grid grid-cols-3 gap-5">
          <FormControl fullWidth>
            <TextField
              label="Автор"
              name="author"
              value={formData.author}
              placeholder="ФИО"
              onChange={handleChange}
              className="w-full"
              variant="outlined"
            />
            {/* {attractionsSuccess &&
                attractions.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    <Checkbox checked={formData.attractions.indexOf(s.id) > -1} />
                    <ListItemText primary={s.title} />
                  </MenuItem>
                ))} */}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="tags">Ключевые направления</InputLabel>
            <Select
              name="tags"
              label="Ключевые направления"
              value={formData.tags}
              onChange={handleChange}
            >
              {tagsSuccess &&
                tags.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <CustomButton
            styles={" w-full h-full min-w-[50px] !m-0 bg-text-primary-green"}
            label="+ Добавить ключевое направление"
            type="button"
          >
            Добавить ключевое направление
          </CustomButton>
        </div>
        <TextEditor
          className={"mt-4"}
          placeholder="Описание маршрута"
          name="description"
          onChange={handleChange}
          value={formData.description}
        />
        <FormControl fullWidth>
          <TextField
            label="Короткое описание"
            multiline
            name="short_description"
            className="!mt-4"
            onChange={handleChange}
            value={formData.short_description}
            rows={4} // Количество строк по умолчанию
            variant="outlined" // Вариант обводки (outlined, filled, стандартный)
            fullWidth // Растянуть текстовое поле на всю ширину контейнера
          />
        </FormControl>
        <div className="flex mt-4 gap-5">
          <p className="">Источники литературы</p>
          <div className="flex flex-col grow gap-4">
            {formData.sources.map((el, index) => (
              <FormControl fullWidth>
                <div className="flex gap-4">
                  <TextField
                    key={index}
                    label="Источники литературы"
                    name="sources"
                    placeholder="Введите ссылку или название"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [e.target.name]: prev.sources.map((input, idx) =>
                          idx === index ? e.target.value : input
                        ),
                      }))
                    }
                    value={formData.sources[index]}
                    variant="outlined" // Вариант обводки (outlined, filled, стандартный)
                    fullWidth // Растянуть текстовое поле на всю ширину контейнера
                  />
                  {index === formData.sources.length - 1 ? (
                    <CustomButton
                      styles={"min-w-[150px] h-full bg-text-primary-green !m-0"}
                      label="Добавить"
                      type="button"
                      onClick={() =>
                        setFormData((prev) => ({
                          ...prev,
                          sources: [...prev.sources, ""],
                        }))
                      }
                    >
                      Добавить
                    </CustomButton>
                  ) : (
                    <CustomButton
                      styles={"min-w-[150px] h-full bg-text-gray !m-0"}
                      label="Удалить"
                      type="button"
                      onClick={() =>
                        setFormData((prev) => ({
                          ...prev,
                          sources: prev.sources.filter(
                            (_, idx) => index !== idx
                          ),
                        }))
                      }
                    >
                      Добавить
                    </CustomButton>
                  )}
                </div>
              </FormControl>
            ))}
          </div>
        </div>
        <div className="mt-4 flex gap-5">
          <FormControl fullWidth className="mt-4">
            <InputLabel id="type">Организация-автор маршрута</InputLabel>
            <Select
              labelId="type"
              name="type"
              label="Организация-автор маршрута"
              value={formData.type}
              onChange={handleChange}
            >
              {typeSuccess &&
                type.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <CustomButton
            styles={" min-w-[150px] !m-0 h-12 bg-text-primary-green"}
            label="+ Добавить новый"
            type="button"
          >
            + Добавить новый
          </CustomButton>
        </div>
        <CustomButton
          styles={" min-w-[150px]  w-[50px] h-12 !mt-4 bg-text-primary-green"}
          label="Отправить"
          type="button"
        >
          Отправить
        </CustomButton>
      </form>
    </section>
  );
};
