import { ReactComponent as destination } from "./destination.svg";
import { ReactComponent as home } from "./home.svg";
import { ReactComponent as notification } from "./notification.svg";
import { ReactComponent as location } from "./location.svg";
import photo from "./photo.png";

const images = { destination, home, photo, notification, location };
export const IMAGES = {
  HOME: "home",
  DESTINATION: "destination",
  PHOTO: "photo",
  NOTIFICATION: "notification",
  LOCATION: "location",
};

export default images;
