import Image from "@features/backoffice/assets/icons";

export const Icon = ({
  size = { width: 30, height: 30 },
  color = "#2FAC66",
  icon,
  fill,
}) => {
  const IcomImage = Image[icon];
  const className = `rounded-xl flex items-center justify-center `;
  return (
    <div
      className={className}
      style={{ backgroundColor: color, width: size.width, height: size.height }}
    >
      <IcomImage width={"50%"} height={"50%"} fill={fill} />
    </div>
  );
};
