import { Divider } from "@components";
import { Breadcrumbs } from "@components/breadcrumbs";
import { IMAGES } from "@features/backoffice/assets/icons";
import { Account } from "@features/backoffice/UI/Header";
import { travelSchoolLogoBlack } from "@images";
import { useLocation } from "react-router-dom";
import { backOfficeRoutes } from "routes";
import { NotificationIcon } from "@shared/ui";

export const Header = () => {
  const { pathname } = useLocation();

  const title =
    backOfficeRoutes.find((el) => el.path === pathname)?.title ||
    backOfficeRoutes[0].children?.find((el) => pathname.endsWith(el.path))
      ?.title;

  return (
    <>
      <header className="fixed w-screen top-0 h-[72px] ">
        <div className="flex h-full items-center">
          <div className="flex h-full w-60 items-center px-4">
            <img src={travelSchoolLogoBlack} alt="logo" />
          </div>
          <div className="flex grow items-center justify-center font-normal">
            <div className="w-11/12 flex items-center gap-8">
              {" "}
              <div className="header-route grow">
                <Breadcrumbs
                  routesProp={backOfficeRoutes}
                  className={" w-max text-xs font-medium md:flex gap-2 inset-0"}
                />
                <span className="font-bold text-sm">{title}</span>
              </div>
              <Account name={"Николай"} img={IMAGES.PHOTO} />
              <NotificationIcon isCount={10} />
            </div>
          </div>
        </div>

        <Divider style={{ margin: 0 }} />
      </header>{" "}
    </>
  );
};
