export const BadgeStatus = ({ status }) => {
  const buildColor = () => {
    switch (status) {
      case "completed":
        return "#E99B26";
      case "work":
        return "#2FAC66";
      case "approve":
        return "#D9D9D9";

      default:
        return null;
    }
  };
  const buildStatus = () => {
    switch (status) {
      case "completed":
        return "Завершенная";
      case "work":
        return "В работе";
      case "approve":
        return "Согласование";

      default:
        return null;
    }
  };

  return (
    <div className="flex rounded-3xl bg-white gap-1 items-center justify-center ">
      <span
        className="h-2 w-2 rounded-full"
        style={{ backgroundColor: buildColor() }}
      ></span>{" "}
      <span>{buildStatus()}</span>{" "}
    </div>
  );
};
