import React, { useCallback, useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { TextField, Skeleton, InputAdornment } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetRouteByIdQuery } from "../../../store/RTKApi/routes";
import { calendarOutdoor } from "../../../images";
import { CustomButton, DayForm, DaysPopup } from "../../../components";
import { secToDays } from "../../../utils/transformDuration";
import { useGetOutDoorEventsFormByIdQuery } from "../../../store/RTKApi/outdoorEvents";
import { numberToText } from "../../../utils/transformDate";
import { outdoorEventsService } from "../../../service";
import { toast } from "react-toastify";

export const AddNote = () => {
  const [noteData, setNoteData] = useState({
    stars: 0,
    days: {},
    content: "",
  });

  const navigate = useNavigate();

  const days = Object.keys(noteData.days).map((key) => Number(key));
  const [daysPopup, setDaysPopup] = useState(false);

  const toggleOpenDaysPopup = () => {
    setDaysPopup((prev) => !prev);
  };

  const { id } = useParams();

  const { data: outdoorEvent, isSuccess: successData } =
    useGetOutDoorEventsFormByIdQuery(id);

  const [getRouteById, { data: routeData, isSuccess }] =
    useLazyGetRouteByIdQuery();

  useEffect(() => {
    if (successData) {
      getRouteById(outdoorEvent.route);

      setNoteData((prev) => ({
        ...prev,
        dateStart: outdoorEvent.date_leave_moscow,
        dateEnd: outdoorEvent.date_return_moscow,
      }));
    }
  }, [successData]);

  const handleNoteChange = ({ target }) => {
    setNoteData((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const handleChangeStars = (star) => {
    setNoteData((prev) => ({ ...prev, stars: star }));
  };
  const handleAddDay = useCallback((number) => {
    setNoteData((prev) => ({
      ...prev,
      days: { ...prev.days, [number]: "" },
    }));

    toggleOpenDaysPopup();
  }, []);

  const handleDeleteDay = (idx) => {
    const updateNote = structuredClone(noteData);

    delete updateNote.days[idx];
    setNoteData(updateNote);
  };

  // content
  // stars

  const handleDayChange = (day, data) => {
    setNoteData((prev) => ({
      ...prev,
      days: { ...prev.days, [day]: { ...prev.days[day], ...data } },
    }));
  };

  const durationDays = secToDays(routeData?.duration);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const images = {};

    // Собираем изображения по дням
    for (const day in noteData.days) {
      for (const image of noteData.days[day]?.images) {
        images[day] =
          images[day]?.length > 0 ? [...images[day], image] : [image];
      }
    }

    const uploadPromises = [];
    const imageKeys = Object.keys(images);

    // Подготавливаем данные и запросы для отправки на сервер
    imageKeys.forEach((day) => {
      images[day].forEach((imageObj) => {
        const formData = new FormData();
        const imageFile = imageObj.file;
        formData.append("media_item", imageFile, imageFile.name.slice(0, 99));

        const request = outdoorEventsService
          .sendMedia(formData)
          .then((response) => ({
            day: day,
            data: response, // Предполагаем, что ответ содержит нужные данные
          }));

        uploadPromises.push(request);
      });
    });

    try {
      const responses = await Promise.all(uploadPromises);

      // Создаём новую структуру на основе ответов сервера
      const newImagesStructure = responses.reduce((acc, { day, data }) => {
        const { id, media_item } = data; // Предполагаем, что ответ сервера содержит эти поля
        if (!acc[day]) acc[day] = [];
        acc[day].push({ id, media_item });
        return acc;
      }, {});

      setNoteData((currentNoteData) => {
        const updatedDays = { ...currentNoteData }; // Копируем текущие дни, чтобы не мутировать исходный объект

        for (const [day, images] of Object.entries(newImagesStructure)) {
          // Для каждого дня в новой структуре обновляем images в соответствующем дне в noteData
          if (updatedDays.days[day]) {
            // Обновляем images для каждого дня, используя информацию из newImagesStructure
            updatedDays.days[day].images = images.map((image) => ({
              id: image.id, // Пример того, как может выглядеть обновление,
              // но вам нужно адаптировать структуру под ваш случай
              media_item: image.media_item, // Здесь пример добавления URL превью; это тоже нужно адаптировать
            }));
          }
        }
        for (const day in updatedDays.days) {
          if (!updatedDays.days[day].content) {
            updatedDays.days[day].content = "";
          }
          if (!updatedDays.days[day].stars) {
            updatedDays.days[day].stars = 0;
          }
        }
        outdoorEventsService
          .sendNote({
            data: updatedDays,
            to_route: routeData.id,
            to_outdoor_event: outdoorEvent.id,
          })
          .then((data) => toast.success("Заметка добавлена"));

        return {
          ...currentNoteData, // Копируем остальную часть noteData
          ...updatedDays, // Заменяем days обновленными данными
        };
      });
    } catch (error) {
      toast.warning("Что то пошло не так");
    } finally {
      navigate(-1);
    }
  };
  return (
    <>
      <section>
        {isSuccess && daysPopup && (
          <DaysPopup
            handleAdd={handleAddDay}
            count={durationDays}
            hasDays={days}
            onClose={toggleOpenDaysPopup}
          />
        )}
        <h2 className="text-2xl font-bold">Создание заметки</h2>

        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="common">
            <div className="flex items-center gap-2">
              <span className="leading-loose">{noteData.stars}/5</span>
              <ReactStars
                count={5}
                onChange={handleChangeStars}
                value={noteData.stars}
                size={30}
                activeColor="#ffd700"
              />
            </div>

            <div className="flex gap-4 mt-7">
              <TextField
                className="w-full max-w-xl "
                disabled
                label="Маршрут"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!isSuccess ? (
                        <Skeleton variant="text" width={100} />
                      ) : (
                        routeData.title
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="input-with-icon-textfield"
                disabled
                className="max-w-[170px] "
                label="Дата выезда"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!successData ? (
                        <Skeleton variant="text" width={100} />
                      ) : (
                        new Date(
                          outdoorEvent.date_leave_moscow
                        ).toLocaleDateString()
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <img src={calendarOutdoor} alt="calendar icon" />
                  ),
                }}
              />
            </div>
            <TextField
              fullWidth
              minRows={3}
              multiline
              required
              margin="normal"
              label="Общее описание"
              name="content"
              onChange={handleNoteChange}
            />
          </div>

          <div className="days my-10">
            {days.length > 0 &&
              days.map((dayNumber) => (
                <DayForm
                  key={dayNumber}
                  dayNumber={dayNumber}
                  dayTitle={numberToText(dayNumber)}
                  handleDelete={() => handleDeleteDay(dayNumber)}
                  onFormChange={handleDayChange}
                />
              ))}
          </div>
          <CustomButton
            type="button"
            styles={
              " w-full max-w-[265px] min-w-[50px] bg-[#EBEBEB] !text-[#151515] hover:bg-text-secondary-green hover:!text-white"
            }
            disabled={days.length === durationDays}
            onClick={toggleOpenDaysPopup}
          >
            Добавить день
          </CustomButton>
          <CustomButton
            styles={
              " w-full max-w-[265px] min-w-[50px] bg-text-primary-green hover:bg-text-secondary-green mt-4"
            }
          >
            Опубликовать
          </CustomButton>
        </form>
      </section>
    </>
  );
};

// {
//   to_outdoorEvents: string,
//   data: {
//     content: string
//     stars: int,
//     days: {
//       [dayNumber]: {
//         content: string,
//         stars: int,
//         images: string[]
//       }
//     }
//   }
// }
