import { IMAGES } from "@features/backoffice/assets/icons";
import { CardItem } from "./cardItem";

const items = [
  {
    title: "Маршруты",
    total: 2300,
    count: 1,
    icon: {
      image: IMAGES.DESTINATION,
      size: { width: 45, height: 45 },
      fill: "#FFFFFF",
      bgFill: "#2FAC66",
    },
  },
  {
    title: "Точки",
    total: 4231,
    count: 12,
    icon: {
      image: IMAGES.LOCATION,
      size: { width: 45, height: 45 },
      fill: "#FFFFFF",
      bgFill: "#2FAC66",
    },
  },
  {
    title: "Уведомления",
    total: 33,
    count: 0,
    icon: {
      image: IMAGES.NOTIFICATION,
      size: { width: 45, height: 45 },
      fill: "#FFFFFF",
      bgFill: "#E99B26",
    },
  },
];

export const CardList = () => {
  return (
    <div className="flex justify-between">
      {items.map((item) => (
        <CardItem
          key={item.title}
          title={item.title}
          count={item.count}
          total={item.total}
          icon={item.icon}
        />
      ))}
    </div>
  );
};
