import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Image from "@features/backoffice/assets/icons";
import { memo } from "react";

export const Account = memo(({ img, name }) => {
  const IconImage = Image[img];

  return (
    <div className="flex gap-3 items-center">
      <div className="rounded-full w-8 h-8">
        <img className="max-w-none" src={IconImage} alt="" />
      </div>
      <span className="text-[#1F384C] text-xs">{name}</span>
      <ChevronDownIcon width={20} />
    </div>
  );
});
