import { useRoutes, Routes, Route, Outlet } from "react-router-dom";
import { backOfficeRoutes, routes } from "./routes";
import withRedux from "./hoc/withRedux";

import AppLoader from "./hoc/appLoader";
import { Footer, Header } from "./components";
import { transformDuration } from "./utils/transformDuration";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { ModalProvider } from "./hooks/useModal";
import { MediaModel } from "./components/ui";
import { LoginProvider } from "./hooks/useLogin";
import { useResize } from "./hooks/useResize";
import { BackOfficePage } from "@pages/backoffice";

function App() {
  const appRoutes = useRoutes(routes);
  const backOfficeRoute = useRoutes(backOfficeRoutes);
  const { isScreenMd } = useResize();
  return (
    <>
      <AppLoader>
        <ToastContainer position={isScreenMd ? "top-right" : "bottom-center"} />

        <Routes>
          {/* <Route path="/backoffice/*" element={backOfficeRoute} /> */}

          <Route
            element={
              <div className="relative min-h-screen flex flex-col justify-between">
                <LoginProvider>
                  <Header />
                  <ModalProvider>
                    <main className="h-full flex-grow mb-20">{appRoutes}</main>
                    <MediaModel />
                  </ModalProvider>
                  <Footer />
                </LoginProvider>
              </div>
            }
            path="/*"
          />
        </Routes>
      </AppLoader>
    </>
  );
}

const AppWithStoreAndRoutes = withRedux(App);
export default AppWithStoreAndRoutes;
