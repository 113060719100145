import { Icon } from "@shared/ui/icon";
import style from "./style.module.css";
import { useEffect, useState } from "react";

export const Card = ({
  icon,
  title,
  fill,
  isActive,
  onClick,
  bgFill,
  children,
}) => {
  const [open, setOpen] = useState(isActive);
  //   console.log(img);
  const handleClick = () => {
    setOpen((prev) => !prev);
    onClick && onClick();
  };

  useEffect(() => {
    if (!isActive) setOpen(false);
  }, [isActive]);
  return (
    <>
      <div
        onClick={handleClick}
        className={
          "h-14 flex gap-3 items-center px-4 cursor-pointer " +
          (isActive && style.active)
        }
      >
        <Icon icon={icon} fill={fill} color={bgFill} />
        <span className="font-bold">{title}</span>
      </div>

      {children && open && (
        <ul className="flex flex-col font-semibold text-xs gap-2 pl-10 mt-5">
          {children}
        </ul>
      )}
    </>
  );
};
