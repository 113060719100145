import Image from "@features/backoffice/assets/icons";
import { memo } from "react";

export const NotificationIcon = memo(({ isCount }) => {
  return (
    <div className="relative">
      {/* Иконка колокола */}
      <Image.notification fill={"#B0C3CC"} />

      {/* Бейдж для уведомлений */}
      {isCount && (
        <span className="absolute top-0 right-0 inline-flex h-2 w-2 rounded-full bg-red-500"></span>
      )}
    </div>
  );
});
