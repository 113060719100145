import { CardList } from "../info/ui";
import { RouteItem } from "../routes/ui/routeItem";

export const Main = () => {
  return (
    <section className="mainBackOffice">
      <CardList />

      <RouteItem />
    </section>
  );
};
