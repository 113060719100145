import { IMAGES } from "@features/backoffice/assets/icons";
import { Card } from "@features/backoffice/UI/Sidebar/card";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import style from "./style.module.css";

export const Sidebar = () => {
  const [isActive, setIsActive] = useState(0);
  return (
    <aside className="w-60 fixed left-0 top-0 min-h-screen bg-[#F6F8FA] pt-20 px-3">
      <Link to={"/backoffice"} onClick={() => setIsActive(0)}>
        <Card
          isActive={isActive === 0}
          icon={IMAGES.HOME}
          title={"Главная"}
          bgFill={isActive !== 0 ? "#FFFFFF" : "#2FAC66"}
          fill={isActive === 0 ? "#FFFFFF" : "#2FAC66"}
        />
      </Link>
      <Card
        onClick={() => setIsActive(1)}
        isActive={isActive === 1}
        icon={IMAGES.DESTINATION}
        title={"Маршруты"}
        fill={isActive === 1 ? "#FFFFFF" : "#2FAC66"}
        bgFill={isActive !== 1 ? "#FFFFFF" : "#2FAC66"}
      >
        <NavLink end={"/"} className={style.link} to={""}>
          Маршруты
        </NavLink>
        <NavLink className={style.link} to={"create"}>
          Создать маршрут
        </NavLink>
        <NavLink className={style.link} to={"work"}>
          Маршруты в работе
        </NavLink>
        <NavLink className={style.link} to={"points"}>
          Точки на маршруте
        </NavLink>
      </Card>
    </aside>
  );
};
