import axios from "axios";
import config from "../config.json";

const http = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? config.baseUrl
      : config.baseUrlProd,
});

const httpService = {
  get: http.get,
};

export default httpService;
