import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CustomButton, CustomCheckbox } from "../../ui";
import { useEffect, useState } from "react";
import { CustomSwitch } from "../../ui/mui/switch";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetRoutesNodesByIdQuery,
  useGetRoutesNodesQuery,
  useLazyGetRouteByIdQuery,
} from "../../../store/RTKApi/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvisors,
  getEducationProgram,
  getStudentsWithGrade,
} from "../../../store/schools/selectors";
import { useLazyGetAllStudentQuery } from "../../../store/RTKApi/schools";
import { setStudents } from "../../../store/schools";
import {
  useAddNewOutDoorEventMutation,
  useGetOutDoorEventsFormByIdQuery,
  usePatchOutDoorEventMutation,
} from "../../../store/RTKApi/outdoorEvents";
const edu = [
  { label: "СПО", value: "SPO" },
  { label: "СОО", value: "SOO" },
  { label: "ООО", value: "OOO" },
  { label: "НОО", value: "NOO" },
];

const EventsOrganizationForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const advisors = useSelector(getAdvisors());
  const edu_program = useSelector(getEducationProgram());
  const studentsWithGrade = useSelector(getStudentsWithGrade());

  const [studentInput, setStudentInput] = useState("");

  const hasEditSegment = location.pathname.includes("/edit");

  const [
    routeTrigger,
    { data: routeData, isLoading: routeLoading, isSuccess: routeSuccess },
  ] = useLazyGetRouteByIdQuery();
  const { data: pointData, isSuccess: pointSuccess } =
    useGetRoutesNodesByIdQuery(routeData?.points[0], {
      skip: !routeSuccess,
    });

  const { data: editData, isSuccess: editSuccess } =
    useGetOutDoorEventsFormByIdQuery(id, {
      skip: !hasEditSegment,
    });

  //TEST CONSTRUCTION
  const { data: allPointsData, isSuccess: allPointsDataSuccess } =
    useGetRoutesNodesQuery(
      { id: routeData?.points.join(",") },
      {
        skip: !routeSuccess,
      }
    );
  const [
    studentSearchTrigger,
    { data: studentSearchData, isSuccess: studentSearchSuccess },
  ] = useLazyGetAllStudentQuery();

  const [outDoorMutation, { isSuccess: outDoorSuccess }] =
    useAddNewOutDoorEventMutation();

  const [formData, setFormData] = useState({
    routeSwitch: false,
    route: "",
    title: "",
    destination_address: "",
    educational_program: ["SPO", "SOO", "OOO", "NOO"],
    leader: null,
    accompany: [],
    date_return_moscow: dayjs(),
    date_leave_moscow: routeData?.duration
      ? dayjs().add(routeData?.duration, "seconds")
      : dayjs(),
    bus_ordered: false,
    food_ordered: false,
    students_amount: "",
  });

  const [patchEvent, { isSuccess: patchEventSuccess }] =
    usePatchOutDoorEventMutation();

  // const [studentForm, setStudentForm] = useState(false);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};

    if (!formData.destination_address) {
      newErrors.destination_address = "Это поле обязательно";
    }

    if (formData.educational_program.length === 0) {
      newErrors.educational_program = "Выберите хотя бы одну программу";
    }

    if (!formData.leader) {
      newErrors.leader = "Это поле обязательно";
    }

    if (formData.accompany.length === 0) {
      newErrors.accompany = "Выберите хотя бы одного сопровождающего";
    }

    if (formData.students_amount === 0) {
      newErrors.students_amount = "Значение поля должно быть больше 0";
    }
    if (isNaN(formData.students_amount)) {
      newErrors.students_amount = "Значение должно быть числом";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (id !== undefined && !hasEditSegment) {
      routeTrigger(id);
      setFormData({ ...formData, routeSwitch: true });
    }
  }, [routeTrigger]);

  useEffect(() => {
    if (routeSuccess && routeData) {
    }
  }, [routeSuccess, routeData]);

  useEffect(() => {
    if (allPointsDataSuccess && allPointsData) {
      setFormData({
        ...formData,
        points: allPointsData.map((point) => {
          const name = !!point.name ? point.name : point.address;
          return {
            label: name,
            value: true,
          };
        }),
      });
    }
  }, [allPointsDataSuccess, allPointsData]);

  useEffect(() => {
    if (pointSuccess && pointData) {
      setFormData({
        ...formData,
        route: routeData.title,
        destination_address: pointData.name,
      });
    }
  }, [pointSuccess, pointData]);

  useEffect(() => {
    if (editSuccess) {
      const {
        accompany,
        leader,
        students_amount,
        title,
        date_leave_moscow,
        date_return_moscow,
        destination_address,
        food_ordered,
        bus_ordered,
      } = editData;
      setFormData({
        ...formData,
        accompany: accompany.map((i) => {
          return {
            label: i.full_name,
            value: i.id,
          };
        }),
        leader: {
          label: leader?.full_name,
          value: leader?.id,
        },
        destination_address: destination_address,
        title: title,
        date_leave_moscow: dayjs(date_leave_moscow),
        date_return_moscow: dayjs(date_return_moscow),
        food_ordered: food_ordered,
        bus_ordered: bus_ordered,
        route: null,
        points: !!editData?.points
          ? editData?.points.map((i) => {
              return {
                value: true,
                label: i,
              };
            })
          : [],
        students_amount: Number(students_amount),
        routeSwitch: false,
      });
    }
  }, [editSuccess]);

  const handleForm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const sendData = {
        title: !!routeData ? routeData?.title : formData.title,
        route: !!routeData ? routeData?.id : null,
        date_leave_moscow:
          leaveDateWithDuration.$d === undefined
            ? leaveDateWithDuration
            : leaveDateWithDuration.$d,
        date_return_moscow:
          formData.date_return_moscow.$d === undefined
            ? formData.date_return_moscow
            : formData.date_return_moscow.$d,
        leader: formData?.leader?.value,
        accompany: formData.accompany.map((i) => i.value),
        destination_address: formData.destination_address,
        points: !!formData?.points
          ? formData?.points
              .filter((i) => i.value !== false)
              .map((v) => v.label)
          : [],
        // educational_program: formData.educational_program.map((i) => i.value),
        educational_program: ["SPO", "SOO", "OOO", "NOO"],
        bus_ordered: formData.bus_ordered,
        students_amount: formData.students_amount,
        food_ordered: formData.food_ordered,
        // students: formData.students.map((i) => i.value),
      };
      if (hasEditSegment) {
        await patchEvent({ id: id, body: sendData })
          .unwrap()
          .then((res) => navigate(`${res.id}/success/`));
      } else {
        await outDoorMutation(sendData)
          .unwrap()
          .then((res) => navigate(`${res.id}/success/`));
      }
    } else {
      console.log(errors);
    }
  };

  const handleFormChanger = ({ name, value, event }) => {
    if (name === "routeSwitch") {
      setFormData({
        ...formData,
        routeSwitch: value,
        route: "",
        destination_address: "",
      });
      return;
    }
    if (name === "points") {
      setFormData((prevFromData) => {
        const pointIndex = prevFromData.points.findIndex(
          (i) => i.label === value.label
        );
        prevFromData.points[pointIndex].value =
          !prevFromData.points[pointIndex].value;
        return {
          ...prevFromData,
          [name]: prevFromData.points,
        };
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const leaveDateWithDuration = routeData?.duration
    ? dayjs(formData.date_return_moscow).add(routeData?.duration, "seconds")
    : formData.date_leave_moscow;
  const successForm = location.pathname.endsWith("success/");
  const addNote = location.pathname.endsWith("addNote");

  return (
    <>
      {successForm || addNote ? (
        <Outlet />
      ) : (
        <form
          onSubmit={handleForm}
          className={
            "!font-rubik !text-[9px] flex flex-col gap-[35px] mb-[40px]"
          }
        >
          <div className={"flex gap-[35px] items-center"}>
            {!hasEditSegment && (
              <FormGroup row>
                <FormControlLabel
                  className={"flex gap-[22px] !text-[20px]"}
                  control={
                    <CustomSwitch
                      name={"routeSwitch"}
                      checked={formData.routeSwitch}
                      onChange={(e) =>
                        handleFormChanger({
                          name: "routeSwitch",
                          value: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Выбрать готовый маршрут"
                  labelPlacement="start"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px", // Размер шрифта метки
                    },
                  }}
                />
              </FormGroup>
            )}

            {formData.routeSwitch ? (
              <TextField
                onChange={(e) =>
                  handleFormChanger({ name: "route", value: e.target.value })
                }
                value={formData.route}
                disabled={true}
                sx={{
                  width: "100%",
                  maxWidth: 570,
                  borderRadius: "5px",
                }}
                variant="outlined"
                label="Название маршрута"
              />
            ) : (
              <TextField
                onChange={(e) =>
                  handleFormChanger({ name: "title", value: e.target.value })
                }
                value={formData.title}
                sx={{
                  width: "100%",
                  maxWidth: 570,
                  borderRadius: "5px",
                }}
                variant="outlined"
                label="Название маршрута"
              />
            )}
            {formData.routeSwitch && !hasEditSegment && (
              <CustomButton
                type={"button"}
                styles={
                  "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                }
                onClick={() => navigate("/ways")}
              >
                Смотреть в библиотеке
              </CustomButton>
            )}
          </div>
          <div className={"flex gap-[35px] items-center"}>
            <TextField
              error={!!errors.destination_address}
              helperText={errors.destination_address}
              disabled={formData.routeSwitch === true}
              value={formData?.destination_address}
              onChange={(e) =>
                handleFormChanger({
                  name: "destination_address",
                  value: e.target.value,
                })
              }
              sx={{
                width: "100%",
                maxWidth: 570,
                borderRadius: "5px",
              }}
              variant="outlined"
              label="Адрес назначения"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // defaultValue={`${new Date()}`}
                value={dayjs(formData?.date_return_moscow) || dayjs()}
                onChange={(e) =>
                  handleFormChanger({
                    name: "date_return_moscow",
                    value: e?.$d,
                  })
                }
                label={"Дата выезда"}
                format="DD.MM.YYYY"
                sx={{
                  maxWidth: 170,
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={dayjs(formData?.date_return_moscow) || dayjs()}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              <DatePicker
                // defaultValue={`${new Date()}`}
                value={dayjs(leaveDateWithDuration)}
                onChange={(e) =>
                  handleFormChanger({
                    name: "date_leave_moscow",
                    value: e?.$d,
                  })
                }
                label={"Дата возвращения"}
                format="DD.MM.YYYY"
                sx={{
                  maxWidth: 170,
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData?.date_leave_moscow || dayjs()}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={"flex gap-[55px]"}>
            {/* <CustomButton
              type={"button"}
              styles={
                "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
              }
              onClick={() => setStudentForm(!studentForm)}
            >
              Выбрать группы
            </CustomButton>
            {studentForm ? (
              <Autocomplete
                multiple
                onInputChange={(event, newInputValue) => {
                  setStudentInput(newInputValue);
                  if (!!newInputValue) {
                    studentSearchTrigger({});
                  } else {
                    studentSearchTrigger({ search: newInputValue });
                  }
                  dispatch(setStudents(studentSearchData));
                }}
                value={formData.students}
                getOptionLabel={(option) => option.label}
                options={studentsWithGrade.filter(
                  (option) =>
                    !formData?.students.some(
                      (selected) => selected?.value === option?.value
                    )
                )}
                onChange={(_, value) =>
                  handleFormChanger({ name: "students", value: value })
                }
                sx={{ width: "100%", maxWidth: 470, borderRadius: "5px" }}
                PaperComponent={({ children }) => (
                  <Paper style={{ maxHeight: "150px", overflow: "auto" }}>
                    {children}
                  </Paper>
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <span className={"text-[12px]"}>{option.label}</span>
                    <span style={{ marginLeft: "auto", fontSize: "14px" }}>
                      {option.grade}
                    </span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.students}
                    helperText={errors.students}
                    {...params}
                    label="Ученик"
                  />
                )}
              />
            ) : null}   */}
            <TextField
              error={!!errors.students_amount}
              helperText={errors.students_amount}
              value={formData?.students_amount}
              onChange={(e) =>
                handleFormChanger({
                  name: "students_amount",
                  value: e.target.value,
                })
              }
              sx={{
                width: "100%",
                maxWidth: 570,
                borderRadius: "5px",
              }}
              variant="outlined"
              label="Количество учеников"
            />
          </div>
          {!!formData?.points && (
            <div className={"flex flex-col gap-[18px]"}>
              {formData?.points?.map((p, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value="end"
                    sx={{
                      marginLeft: "10px",
                      display: "flex",
                      gap: "10px",
                      "& .MuiTypography-root": {
                        color: "black", // Задает цвет текста
                        fontWeight: "bold", // Задает жирность текста
                      },
                    }}
                    control={
                      <CustomCheckbox
                        checked={p.value}
                        onChange={(e) =>
                          handleFormChanger({
                            name: "points",
                            value: p,
                            event: e,
                          })
                        }
                      />
                    }
                    label={p.label}
                    labelPlacement="end"
                  />
                );
              })}
            </div>
          )}
          <div className={"flex flex-col gap-[18px]"}>
            <Autocomplete
              onChange={(_, value) =>
                handleFormChanger({ name: "leader", value: value })
              }
              value={formData.leader}
              options={advisors}
              sx={{ width: "100%", maxWidth: 370, borderRadius: "5px" }}
              renderInput={(params) => (
                <TextField
                  error={!!errors.leader}
                  helperText={errors.leader}
                  {...params}
                  label="Руководитель группы:"
                />
              )}
            />
            <Autocomplete
              multiple
              onChange={(_, value) =>
                handleFormChanger({ name: "accompany", value: value })
              }
              value={formData.accompany}
              options={
                advisors.length === 0
                  ? []
                  : advisors.filter(
                      (option) =>
                        !formData?.accompany.some(
                          (selected) => selected?.value === option?.value
                        )
                    )
              }
              sx={{ width: "100%", maxWidth: 370, borderRadius: "5px" }}
              renderInput={(params) => (
                <TextField
                  error={!!errors.accompany}
                  helperText={errors.accompany}
                  {...params}
                  label="Ответственный сопровождающих:"
                />
              )}
            />
            {/*<Autocomplete*/}
            {/*    multiple*/}
            {/*    onChange={(_, value) =>*/}
            {/*        handleFormChanger({name: "educational_program", value: value})*/}
            {/*    }*/}
            {/*    value={formData.educational_program}*/}
            {/*    options={*/}
            {/*        edu === 0*/}
            {/*            ? []*/}
            {/*            : edu.filter(*/}
            {/*                (option) =>*/}
            {/*                    !formData?.educational_program.some(*/}
            {/*                        (selected) => selected?.value === option?.value*/}
            {/*                    )*/}
            {/*            )*/}
            {/*    }*/}
            {/*    sx={{width: "100%", maxWidth: 570, borderRadius: "5px"}}*/}
            {/*    renderInput={(params) => (*/}
            {/*        <TextField*/}
            {/*            error={!!errors.educational_program}*/}
            {/*            helperText={errors.educational_program}*/}
            {/*            {...params}*/}
            {/*            label="Образовательная программа:"*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}
          </div>
          <div className={"flex gap-[70px] items-center"}>
            <div className={"flex gap-[10px] items-center text-[14px]"}>
              <label className={"text-[#7E7E7E]"}>Автобусный: </label>
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                label="Да"
                labelPlacement="end" // Помещает метку слева от чекбокса
                control={
                  <CustomCheckbox
                    checked={formData.bus_ordered === true}
                    onChange={() =>
                      handleFormChanger({
                        name: "bus_ordered",
                        value: true,
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                label="Нет"
                labelPlacement="end" // Помещает метку слева от чекбокса
                control={
                  <CustomCheckbox
                    checked={formData.bus_ordered === false}
                    onChange={() =>
                      handleFormChanger({
                        name: "bus_ordered",
                        value: false,
                      })
                    }
                  />
                }
              />
            </div>
            <div className={"flex gap-[10px] items-center text-[14px]"}>
              <label className={"text-[#7E7E7E]"}>Заказ питания: </label>
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                label="Да"
                labelPlacement="end" // Помещает метку слева от чекбокса
                control={
                  <CustomCheckbox
                    checked={formData.food_ordered === true}
                    onChange={() =>
                      handleFormChanger({
                        name: "food_ordered",
                        value: true,
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                label="Нет"
                labelPlacement="end" // Помещает метку слева от чекбокса
                control={
                  <CustomCheckbox
                    checked={formData.food_ordered === false}
                    onChange={() =>
                      handleFormChanger({
                        name: "food_ordered",
                        value: false,
                      })
                    }
                  />
                }
              />
            </div>
          </div>
          <CustomButton
            styles={
              "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
            }
            type={"submit"}
          >
            {hasEditSegment
              ? "Подтвердить изменения"
              : "Подтвердить мероприятие"}
          </CustomButton>
        </form>
      )}
    </>
  );
};

export default EventsOrganizationForm;
